import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SvgIcon from 'components/SvgIcon'
import Button from '../../Button'
import Typography from '../../Typography'

import IconPhone from '../../../icons/inline/icon-call.svg'

import * as styles from './styles.module.scss'

const CtaItem = ({ image, title, text, btnText, btnIcon, href }) => {
  return (
    <div className={clsx(styles.item)}>
      <div className="t-s t-s-s b-s b-s-s">
        <SvgIcon size="64" responsiveSize="s-48">
          {image}
        </SvgIcon>
      </div>

      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="txt2" className={styles.text}>
        {text}
      </Typography>
      <Button href={href} variant="secondary" className={styles.button}>
        {btnIcon && <SvgIcon size="16"><IconPhone /></SvgIcon>}
        <span>{btnText}</span>
      </Button>
    </div>
  )
}

CtaItem.propTypes = {
    image: PropTypes.any,
    title: PropTypes.any,
    text: PropTypes.any,
    btnText: PropTypes.any,
    btnIcon: PropTypes.any,
    href: PropTypes.string,
}

export default CtaItem
