import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import './styles.scss'

const ButtonGroupItem = ({ children, className }) => (
  <div className={clsx('btn-group__item', className)}>{children}</div>
)

ButtonGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const ButtonGroup = ({ children, className, space }) => (
  <div
    className={clsx(
      'btn-group',
      space && `btn-group--space-${space}`,
      className
    )}
  >
    {children}
  </div>
)

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  space: PropTypes.oneOf(['xl', 'l', 'sm', 'm', 's-m', 's-sm', 's-s']),
}

ButtonGroup.Item = ButtonGroupItem

export default ButtonGroup
