import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import getDimensionsObject from 'utils/getDimensionsObject'
import keyCodes from 'utils/constants/keyCodes'

import './styles.scss'

const MarkdownTemplate = ({ children, variant, asHtml }) => {
  const handleClick = (e) => {
    const el = e.target

    if (el && el.tagName === 'A') {
      const elHref = el.getAttribute('href')

      if (/^#.*/.test(elHref)) {
        e.preventDefault()

        const target = document.querySelector(elHref)

        if (target) {
          const curPos = window.pageYOffset
          const anchorTargetPos = getDimensionsObject(target)

          window.scroll({
            top: curPos + anchorTargetPos.top - 100,
            behavior: 'smooth',
          })
        }
      }
    }
  }

  const handleKeyPress = (e) => {
    if ([keyCodes.ENTER].indexOf(e.which) >= 0) {
      handleClick(e)
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      className={clsx(
        !asHtml ? ('markdown-template', `markdown-template--${variant}`) : ''
      )}
    >
      {children}
    </div>
  )
}

MarkdownTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['one', 'two', 'three', 'four']),
  asHtml: PropTypes.bool,
}

MarkdownTemplate.defaultProps = {
  variant: 'one',
}

export default MarkdownTemplate
