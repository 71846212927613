import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as styles from './styles.module.scss'
import CtaItem from './CtaItem'
import Typography from '../Typography'
import '../../css/main.scss'

import IconCall from '../../icons/inline/icon-call-agent.svg'
import IconAgent from '../../icons/inline/icon-quote-online.svg'

const Cta = ({ title, text, className, content }) => {
  const icons = {
    iconAgent: <IconAgent />,
    iconCall: <IconCall />
  };
  return (
    <div className={className}>
      <div className="center col-12">
        <div className={clsx(styles.cta)}>
          <div className={clsx(styles.left)}>
            <Typography variant="h2" className={clsx(styles.title)}>
              {title}
            </Typography>
            <Typography variant="txt2" className={clsx(styles.text)}>
              {text}
            </Typography>
          </div>
          <div className={clsx(styles.ctaItems)}>
            {content.map((item) => (
              <CtaItem
                key={item.node.frontmatter.id}
                image={icons[item.node.frontmatter.icon]}
                title={item.node.frontmatter.title}
                text={item.node.frontmatter.text}
                btnText={item.node.frontmatter.btnText}
                btnIcon={item.node.frontmatter.btnIcon}
                href={item.node.frontmatter.href}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Cta.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any,
  className: PropTypes.any,
  content: PropTypes.array
}

export default Cta
